body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.map-container-canvas {
  height: 100vh;
  min-width: 100vw; /* Add minimum width */
}

.list-component {
  max-height: 400px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .list-component {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .map-container-canvas {
    height: 60vh; /* Adjust height to accommodate list component */
  }
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.game-input {
  z-index: 5;
  background-color: white;
}

.map-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2; /* ensure button is on top of the map */
}